@import '../constants/colors';

.checkboxContainer {
  display: inline-block;
  vertical-align: middle;
  margin: 0 8px;
  padding: 0.2em 0;
}

.checkbox {
  display: inline-block;
  height: 16px;
  width: 16px;
  background-color: $white;
  border: 1px solid $border-gray;
  border-radius: 3px;
  transition: all 150ms;

  .hiddenCheckbox:focus + & {
    box-shadow: 0 0 3px 3px rgba(56, 93, 234, 0.5);
  }
}

.hiddenCheckbox {
  position: absolute;

  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;

  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
}

.checkmark {
  display: flex;
  justify-content: center;
  align-items: center;

  visibility: hidden;

  fill: none;
  stroke: white;
  stroke-width: 3px;
}

.checked {
  background-color: $blue;

  svg {
    visibility: visible;
  }
}
