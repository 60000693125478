@import '../constants/colors.scss';
@import '../constants/typography.scss';
@import '../constants/animation.scss';

:export {
  searchExpandAnimationMs: $searchbox-expand-animation-length;
}

.flotillaSearchContent {
  background-color: #f5f5f5;
  // min-height: 5em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  text-align: center;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;

  max-height: 35em;
  overflow: auto;

  .resultsInfo {
    padding: 1em;
  }
}

.commandCompletions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5em;

  .commandCompletion:hover {
    background-color: #eee;
  }

  .commandCompletion {
    padding: 0.7em 1em;
    cursor: pointer;
    font-size: 0.8em;

    .command {
      background-color: #eee;
      border-radius: 0.25em;
      border: 1px solid #ccc;
      color: $text-black;
      text-decoration: none;
      margin-right: 1em;
      padding: 0.25em;
    }
  }
}

.flotillaSearchCard {
  background-color: #fff;
  border-radius: 0.25em;
  border: 1px solid #ebebeb;
  display: inline-block;
  text-align: left;
  padding-bottom: 0.5em;
  margin: 0.5em;

  .expandButtonCollapsed::before {
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    content: '';
    height: 300%;
    pointer-events: none;
    background: linear-gradient(
      hsla(0, 0%, 100%, 0) 1%,
      hsla(0, 0%, 100%, 0.8)
    );
  }

  .expandButton {
    position: relative;
    // border: 1px solid black;
    cursor: pointer;
    text-align: center;
    // background-color: #eee;
    padding: 0.5em 0;
    margin-bottom: -0.6em;
    margin-left: 0;
    font-size: 0.8em;
    border-radius: 0 0 4px 4px;
    margin-right: 0;
  }

  .expandButton:hover {
    background-color: #375dea;
    color: white;
  }

  .flotillaResultTitle {
    padding-left: 0.5em;
    padding-top: 0.8em;
    font-weight: bold;
    margin-bottom: 1em;
    margin-left: 0.25em;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .flotillaResultList {
    margin: 0;
    padding: 0;
  }

  .flotillaResult:hover,
  .flotillaResultFocused {
    background-color: #eee !important;
  }

  .flotillaResult {
    padding-left: 0.8em;
    padding-right: 0.7em;
    padding-bottom: 0.6em;
    padding-top: 0.3em;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;

    .flotillaResultLeft {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: stretch;
    }

    cursor: pointer;

    .flotillaResultLink {
      margin-left: 0.7em;

      .linkSubtext {
        font-size: 75%;
        color: #b8b8b8;

        b {
          color: #919191;
        }
      }
    }

    .flotillaResultRight {
      position: relative;
      top: -0.125em;
    }

    .linkButton {
      white-space: nowrap;
      background-color: #212121;
      border-radius: 5px;
      color: #fff;
      padding: 0.5em;
      text-decoration: none;
      font-size: 0.75em;
      transition: all 0.2s ease 0s;
    }

    .linkButton:hover {
      cursor: pointer;
      box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
      transform: translate(-3px, -3px);
    }

    .linkButton:active {
      box-shadow: none;
    }
  }
}

.flotillaSearchContainer {
  width: 25vw;
  min-width: 300px;
  display: inline-block;

  transition: min-width $searchbox-expand-animation-length,
    font-size $searchbox-expand-animation-length;

  font-size: 0.9rem;

  z-index: 10;

  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
}

.flotillaSearchContainerExpanded {
  font-size: 1.1rem;

  width: 40%;
  min-width: 500px;
  max-width: 700px;

  .flotillaSearchInput {
    background-color: #fff !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;

    .inputClearButton {
      position: relative;
      left: -1em;
      cursor: pointer;
      color: #000;

      svg {
        color: #000 !important;
      }
    }

    input {
      border-bottom: 1px solid #ececec;
      color: $text-black;
    }
  }
}

@media (max-width: 600px) {
  .flotillaSearchContainer {
    width: 100%;
    margin-right: 1em;
    min-width: unset;
    font-size: unset;
  }

  .flotillaSearchContainerExpanded {
    min-width: unset;
    font-size: unset;
  }
}

.flotillaSearchActions {
  display: flex;
  flex-wrap: wrap;

  background-color: $white;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  [class~='svg-inline--fa'] {
    margin-bottom: 6px;

    color: $icon-gray;
    font-size: 1.9em;
  }

  button {
    flex: 1;

    color: $text-black;
    font-size: 0.45em;
    font-weight: 900;
    text-transform: uppercase;

    border-radius: 7px;

    &:hover {
      background-color: $gray;
      border-radius: 7px;
    }
  }
}

.autoCompletion {
  position: relative;
  top: -1.8rem;
  margin-left: 0.1em;
  display: block;
  color: #9e9e9e;
  font-size: 21.12px;
  pointer-events: none;
}

.autocompleteInput {
  width: 100%;
  height: 2em;
  overflow: hidden;
}

.searchInfo {
  margin-top: 10px;
  font-size: 0.9em;
  font-weight: 500;
  color: gray;
}

.flotillaSearchInput {
  display: flex;
  align-items: center;
  padding: 1em 16px;

  :focus {
    outline: none;
  }

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  background-color: $blue;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  input {
    width: 100%;
    flex: 1;
    margin-right: 8px;

    font-size: 1.4em;
    font-family: 'HK Grotesk', 'Arial';
    font-weight: 500;

    border: none;
    background-color: transparent;

    color: $white;

    &::placeholder {
      color: $icon-gray;
    }
  }

  [class~='svg-inline--fa'] {
    color: $white;
    font-size: 1.25em;
    opacity: 0.5;
  }
}
