@import '@fortawesome/fontawesome-svg-core/styles.css';

@import './components/constants/colors.scss';
@import './components/constants/typography.scss';
@import './components/constants/animation.scss';
@import './components/constants/breakpoints.scss';
@import './components/constants/layouts.scss';

@import './Home.module.scss';
@import './Flotilla.module.scss';

@import './components/FlotillaToolTip.module.scss';
@import './components/PolyLineOverlay.module.scss';
@import './components/VesselMarker.module.scss';

@import './components/shared/Checkbox.module.scss';

@import './components/FlotillaSearch/FlotillaSearch.module.scss';

@import './components/SidePanel/SidePanel.module.scss';
@import './components/SidePanel/SidePanelHeader.module.scss';

@import './components/SetupWizard/SetupWizard.module.scss';

html,
body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-family: HK Grotesk, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-close-button {
    font-size: 1.8rem;
    padding: 0.25rem 0.5rem;
    top: 0.25rem;
    right: 0.25rem;
    line-height: 0.8;
    border-radius: 8px;
  }

  .mapboxgl-popup-content {
    font-family: HK Grotesk, Roboto;
    box-shadow: 3px 3px 7px 0px rgb(0 0 0 / 10%) !important;
    background: hsl(234deg 43% 99% / 100%);
    border-radius: 10px;
    padding: 0;
    font-weight: 500;
  }

  .mapboxgl-popup-focus {
    /* this is deliberately set to a value lower than .sidePanel: z-index */
    z-index: 9;
    opacity: 1 !important;
  }
}

.MuiTypography-root {
  font-family: HK Grotesk, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

.MuiInputBase-input {
  font-family: HK Grotesk, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.boxdraw {
  background: rgba(255, 0, 0, 0.3);
  border: 3px dashed #f24726;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
}

.vessel-mapbox-popup {
  opacity: 0.8 !important;
  max-width: 24em !important;
}

.vessel-map-popup {
  padding: 10px 16px 5px;
}

.vessel-map-popup ul {
  padding-left: 0;
  margin: 0.2em;
  font-size: 1.2em;
}

.vessel-map-popup li {
  text-shadow: 1px 1px 5px hsl(0deg 0% 0% / 17%);
  padding: 0;
  margin-top: 2px;
  padding-left: 13px;
  list-style: none;
}

.vessel-popup-title {
  font-size: 1.1rem;
  padding-bottom: 4px;
  padding-top: 2px;
  font-weight: 700;
}

.vessel-map-popup li .field-name {
  font-weight: 700;
}

// .vessel-map-popup li.popup-item-course {
//   background: url(../assets/images/course.svg) no-repeat -3px -3px;
// }

// .vessel-map-popup li.popup-item-owner {
//   background: url(../assets/images/owner.svg) no-repeat -3px -3px;
// }
// .vessel-map-popup li.popup-item-signers {
//   background: url(../assets/images/signers.svg) no-repeat -3px -3px;
// }
// .vessel-map-popup li.popup-item-updatedAt,li.popup-item-secondsToNextPort,li.popup-item-arrival,li.popup-item-departure,li.popup-item-localTime {
//   background: url(../assets/images/time.svg) no-repeat -3px -3px;
// }

.waypoint-mapbox-popup {
  font-size: 0.9em;
  opacity: 0.8;

  .mapboxgl-popup-tip {
    border-right-color: rgb(62 94 180 / 100%) !important;
    border-left-color: rgb(62 94 180 / 100%) !important;
  }

  .mapboxgl-popup-content {
    background: rgb(62 94 180 / 100%) !important;
    color: white;
    border-radius: 10px;
    padding: 8px;
  }
}

.waypoint-map-popup ul {
  padding-left: 0;
  margin: 0.2em;
  font-size: 1.2em;
}

.waypoint-map-popup li {
  padding: 0;
  padding-left: 1.5em;
  list-style: none;
}

.waypoint-popup-title {
  display: none;
  font-size: 1.4em;
  padding: 0.4em;
  font-weight: 600;
}

.waypoint-map-popup li.location-item {
  background: url(../assets/images/location.svg) no-repeat -3px -3px;
}
.waypoint-map-popup li.time-item {
  background: url(../assets/images/time.svg) no-repeat -3px -3px;
}

.eta-etd {
  width: 135px;
  height: 10px;
  font-size: 8px;
  color: #8b8b8b;
}

.cta-button {
  // width: 128px;
  // height: 2.2em;
  color: white;
  background-color: #385dea;
  font-family: HK Grotesk, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  border: none;
  box-shadow: none;
  border-radius: 0 0 9px 9px;
  margin: 0;
  font-size: 1em;
  padding: 0.5em 0.2em;
  width: 100%;
  transition: font-size 0.1s ease;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 13px -4px rgb(0 0 0 / 20%);
    font-size: 1.2em;
    padding: 0.6em 0.2em;
    // transform: translate(-1px, -1px);
  }
}

.intercom-lightweight-app,
.intercom-launcher-frame {
  display: none !important;
}
