@import '../constants/colors';
@import '../constants/breakpoints';
.mapPlaceholder {
  height: 100vh;
  width: 100vw;

  background-image: url('../../../assets/images/empty-map-lg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  filter: blur(2px);
}

.modalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;
}

.signinFormContainer {
  width: 100%;
  width: 100%;

  max-width: 350px;
  max-height: 600px;
}

.signinFormInner {
  display: flex;
  flex-direction: column;
  padding: 16px;

  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

  p {
    margin-bottom: 1rem;

    a {
      color: $blue;
      font-weight: 400;

      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: $mobile-max) {
    max-width: unset;
    max-height: unset;
    height: 95%;
  }
}

.gwLogoHeader {
  margin: 30px auto;
}

.submitButton {
  margin-right: 0;
  margin-bottom: 1rem;
  height: 40px;

  font-size: 1rem;
}

.altSigninDivider {
  display: flex;
  align-items: center;
  text-align: center;

  margin-bottom: 1rem;

  color: $text-black;

  &::before {
    margin-right: 1rem;
  }

  &::after {
    margin-left: 1rem;
  }

  &::before,
  &::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #1d3a5b;
  }
}

.altSigninButton {
  margin-right: 0;
  margin-bottom: 1rem;
  padding: 0.5rem;

  font-size: 1rem;
}

.socialMediaLogo {
  margin-right: 0.5rem;
  width: 20px;
}
