.errorContainer {
  margin-top: 10vh;
  padding: 0;
  text-align: center;
  font-size: 20px;
  line-height: 2;
}

.errorLink {
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
