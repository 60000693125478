.toolTipContainer {
  padding: 4px 10px;

  position: absolute;
  left: 20px;
  bottom: 15px;

  color: white;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);

  border-radius: 5px;

  z-index: 100;
}

@media (max-width: 600px) {
  .toolTipContainer {
    display: none;
  }
}
