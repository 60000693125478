@import '../constants/colors.scss';
@import '../constants/animation.scss';

$sidebar-animation-length-ms: $sidebar-animation-length + 0ms;

$sidepanel-collapse-padding: 16px;
$sidepanel-delete-button-width: 40px;

:export {
  sideBarAnimationMs: $sidebar-animation-length;
}

.sidePanel {
  display: flex;
  flex-direction: column;

  min-width: 300px;

  height: 100vh;
  width: 25vw;

  position: absolute;
  right: -100%;
  top: 0;
  opacity: 0;

  // padding-top: 10px;

  transition: right $sidebar-animation-length-ms,
    opacity $sidebar-animation-length-ms;

  // background-color: white;
  background-color: $gray;
  border: 1px solid $border-gray;

  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
  }

  z-index: 1000;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);

  h1 {
    padding: 0 16px;
  }

  // ul {
  //   margin-top: 16px;
  //   margin-top: 0;
  //   padding: 0;
  // }

  // li {
  //   margin-bottom: 16px;

  //   list-style: none;
  // }
}

.sidebarCloseButtonContainer {
  display: flex;
  justify-content: right;
  position: absolute;
  top: 5px;
  right: 0;
  // margin-top: -10px;
}

.closeButton {
  width: 40px;
  height: 40px;
  background-color: #f3f3f4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -15px;
  &:hover {
    background-color: #e7e7e9;
  }
}

@media (max-width: 600px) {
  .sidePanel {
    // width: 80vw;
  }
}

.sidePanelOpened {
  right: 0;
  opacity: 1;
}

.deleteButton {
  position: absolute;
  // box-shadow: inset -10px 0 10px -10px #000000;
  top: 0;
  left: 0;
  height: 100%;
  width: $sidepanel-delete-button-width;
  display: flex;

  svg {
    margin: auto;
  }
}

.expandableTrigger {
  position: relative;
  // height: 100%;

  &:hover .expandableTriggerDescription {
    cursor: pointer;

    left: $sidepanel-delete-button-width;
    width: calc(100% - $sidepanel-delete-button-width);
  }
}

.expandableTriggerDescription {
  position: relative;
  z-index: 9;
  background-color: white;

  left: 0;
  width: calc(100%);

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;

  transition: $delete-button-animation-length ease-out;

  // border-top: 1px solid $border-gray;
  // border-bottom: 1px solid $border-gray;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #f2f2f2;
  }
}

.Collapsible {
  border-bottom: 1px solid $border-gray;

  &:first-of-type {
    border-top: 1px solid $border-gray;
  }
}

.SidePanelVesselAnimated {
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    opacity 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateX(200px);
  opacity: 0.5;
}

.SidePanelVesselDisplayed {
  transform: translateX(0);
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
}

.vesselPortCards {
  display: flex;
  flex-wrap: wrap;
}

// .vesselDetailsContainer {
//   flex: 1;
//   padding: 0px 16px;
// }

.vesselDetailTitle {
  margin-bottom: 6px;

  color: $text-gray;
  font-size: 0.7rem;
  text-transform: uppercase;
}

.vesselDetailContent {
  margin: 0;

  color: $text-black;
  font-size: 1.1rem;
}
