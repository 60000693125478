@import '../constants/colors';
@import '../constants/breakpoints';

.setupWizardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.setupWizardContent {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 75%;
  height: fit-content;
  max-width: 900px;

  background-color: $white;
  border-radius: 4px;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

  @media screen and (max-width: $mobile-max) {
    height: 95%;
    width: 95%;
  }
}

[class~='role-select'],
[class~='setup-notifications'],
[class~='add-vessel'] {
  height: fit-content;
  max-width: 700px;

  @media screen and (max-width: $mobile-max) {
    height: 95%;
    width: 95%;
  }
}

.setupWizardWelcome {
  margin-bottom: 24px;

  @media screen and (max-width: $mobile-max) {
    margin-bottom: 0;
  }
}

.setupStepsContainer {
  flex: 1;
  width: 100%;
  padding: 24px;

  @media screen and (max-width: $mobile-max) {
    display: flex;
    flex-direction: column;

    &[class~='current-step-5'] {
      overflow: hidden;
    }
  }
}

.setupWizardHeader {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (max-width: $mobile-max) {
    margin-bottom: 16px;

    h2,
    h3 {
      font-size: 18px;
    }
  }
}

.selectedSidePanelCheckbox:hover {
  background-color: #eee;
}

.stepNumber {
  margin: 0;
  padding: 0;
  margin-right: 15px;
  padding-right: 15px;

  border-right: 1px solid $border-gray;

  color: $blue;
  font-size: 32px;
  font-weight: 900;
}

.roleSelectOptions {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  text-align: center;

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 105px;
    width: 30%;
    max-width: 195px;

    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    border: 1px solid $border-gray;
    border-radius: 4px;

    &:not(:last-of-type) {
      margin-right: 24px;
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      fill: $icon-gray;
    }
  }

  [class~='selected'] {
    border: 2px solid $blue;

    svg {
      fill: $blue;
    }
  }

  @media screen and (max-width: $mobile-max) {
    flex-direction: column;
    align-items: center;

    margin-top: 16px;

    li {
      width: 100%;
      max-width: unset;

      margin-right: 0 !important;
      margin-bottom: 16px;
    }
  }
}

.popUpSelectWrapper,
.sidePanelSelectWrapper,
.highlightVesselWrapper {
  display: flex;
  max-height: 50vh;

  @media screen and (max-width: $mobile-max) {
    height: 100%;
    max-height: unset;
    flex: 1;
    flex-direction: column-reverse;
  }
}

.selectListWrapper {
  position: relative;
  flex: 1;

  p {
    position: absolute;
    top: -25px;
    white-space: nowrap;
  }

  @media screen and (max-width: $mobile-max) {
    max-height: 200px;

    [class~='variant-error'] {
      position: relative;
      top: 0;
    }

    [class~='MuiFormControlLabel-label'] {
      font-size: 14px;
    }

    [class~='MuiRadio-root'] {
      padding: 4px 9px;
    }
  }
}

.selectListWrapper::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: '';
  height: 20%;
  pointer-events: none;
  background: linear-gradient(
    hsla(0, 0%, 100%, 0) 1%,
    hsla(0, 0%, 100%, 0.8) 50%,
    hsla(0, 0%, 100%, 0.9) 90%
  );
}

.selectListWrapper:hover::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  content: '';
  height: 10%;
  pointer-events: none;
  background: unset;
}

.selectList {
  max-height: 100%;
  padding: 8px 0;
  margin: 0;

  list-style: none;
  overflow-y: auto;

  // Overrides MUI
  flex-wrap: unset !important;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  li:not(:last-of-type) {
    margin-bottom: 4px;
  }

  @media screen and (max-width: $mobile-max) {
    label {
      font-size: 14px;
    }
  }
}

.setupWizardMap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 2;
  margin-left: 8px;
  position: relative;

  border: 1px solid $border-gray;
  border-radius: 4px;

  background-image: url('../../../assets/images/empty-map.png');
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: $mobile-max) {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: none;
  }
}

.popUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  margin-bottom: 10px;

  background-color: $white;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  h4 {
    margin-bottom: 8px;
    padding: 12px 24px 0;
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: $blue transparent transparent transparent;
  }
}

.popUpList {
  padding: 0 24px;
  margin: 0;
  margin-bottom: 8px;
  margin-left: 25px;
  list-style: none;

  font-size: 1rem;

  li {
    margin-bottom: 4px;

    b {
      margin-right: 0px;
    }
  }
}

.popUpListItemLabel {
  margin-right: 4px !important;
}

.mockButton {
  width: 100%;
  padding: 4px 8px;

  font-size: 14px;
  text-align: center;

  color: white;
  background-color: $blue;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  &:hover {
    cursor: pointer;
  }
}

.sidePanelContainer {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: $mobile-max) {
    min-height: 200px;
  }
}

.sidePanelList {
  position: absolute;
  right: 0;
  height: 100%;
  margin: 0;
  padding-top: 16px;
  background-color: $gray;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
  list-style: none;
  overflow-y: auto;

  font-size: 0.8rem;

  h2 {
    font-size: 1rem;
  }

  p {
    font-size: 0.8rem;
  }
}

.setupWizardTransitionGroup {
  height: 100%;
  width: 100%;
}

.highlightVesselLegend {
  list-style: none;
  padding: 8px 8px 8px 0;
  margin: 0;

  position: absolute;
  top: 10px;
  left: 10px;

  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);

  li {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    svg {
      margin-right: 8px;
    }

    p {
      margin: 0;
    }
  }

  @media screen and (max-width: $mobile-max) {
    position: relative;
  }
}

.popUpVesselContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 10px;
  bottom: 5px;

  @media screen and (max-width: $mobile-max) {
    display: none;
  }
}

.addVesselInputContainer {
  margin-bottom: 16px;

  p {
    margin-bottom: 8px;
  }
}

.vesselListContainer {
  margin: 8px auto;

  p {
    margin-bottom: 16px;
  }

  @media screen and (max-width: $mobile-max) {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}

.vesselList {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  margin: 0;
  padding: 0;

  button {
    margin-bottom: 10px;
  }

  @media screen and (max-width: $mobile-max) {
    height: unset;
    max-height: unset;
  }
}

.notificationsContainer {
  width: 100%;

  @media screen and (max-width: $mobile-max) {
    flex: 1;
  }
}

.notificationsHeader {
  padding: 16px;

  border-bottom: 1px solid $border-gray;

  h3 {
    margin-bottom: 8px;
  }
}

.notificationOptionsList {
  margin: 0;
  padding: 0;
  list-style: none;

  @media screen and (max-width: $mobile-max) {
    label {
      font-size: 14px;
    }
  }
}

.notificationOptionsList li {
  margin-bottom: 0.3em;
}

.notificationPreferencesContainer {
  display: flex;
  padding: 16px;

  border-bottom: 1px solid $border-gray;

  > * {
    flex: 1;
  }

  @media screen and (max-width: $mobile-max) {
    flex-direction: column;

    h5 {
      margin-bottom: 16px;
    }
  }
}

.contactInformationContainer {
  display: flex;
  padding: 16px;

  > * {
    flex: 1;
  }

  @media screen and (max-width: $mobile-max) {
    flex-direction: column;

    h5 {
      margin-bottom: 16px;
    }
  }
}

.contactFormContainer {
  width: 100%;

  > div {
    margin-bottom: 16px;
  }

  @media screen and (max-width: $mobile-max) {
    > div {
      margin-bottom: 0;
    }

    [class~='MuiFormControl-root'] {
      margin-bottom: 24px !important;
    }
  }
}

.nameInputContainer {
  display: flex;

  [class~='MuiFormControl-root']:first-of-type {
    margin-right: 8px;
  }

  @media screen and (max-width: $mobile-max) {
    flex-direction: column;

    [class~='MuiFormControl-root']:first-of-type {
      margin-right: 0 !important;
    }
  }
}
