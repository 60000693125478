@import '../constants/colors';

$sizes: 12px, 14px, 16px, 18px, 20px, 22px, 24px;
$weights: 100, 200, 300, 400, 500, 600, 700, 800, 900;
$aligns: left, center, right;

.title {
  margin: 0;
  color: $text-black;
}

[class~='h1'] {
  font-size: 24px;
}

[class~='h2'] {
  font-size: 22px;
}

[class~='h3'] {
  font-size: 20px;
}

[class~='h4'] {
  font-size: 18px;
  font-weight: 600;
}

[class~='h5'] {
  font-size: 16px;
}

.subtitle {
  font-size: 16px;
  color: $text-gray;
}

.text {
  margin: 0;

  color: $text-black;
  font-size: 14px;

  // TEXT VARIANTS
  &[class~='variant-muted'] {
    color: $text-gray;
  }

  &[class~='variant-error'] {
    color: $red;
  }

  // TEXT FONT SIZES
  @each $size in $sizes {
    &[class~='size-#{$size}'] {
      font-size: $size;
    }
  }

  // TEXT FONT WEIGHTS
  @each $weight in $weights {
    &[class~='weight-#{$weight}'] {
      font-weight: $weight;
    }
  }

  // TEXT ALIGN
  @each $align in $aligns {
    &[class~='align-#{$align}'] {
      text-align: $align;
    }
  }
}
