.vesselMarker {
  position: relative;
  top: -20px;
  left: -20px;

  z-index: 1;

  &:hover {
    cursor: pointer;
  }
}

.markerPopover {
  transform: translate(-35%, -110%) !important;

  z-index: 10;

  [class*='mapboxgl-popup-content'] {
    padding: 12px;
    box-shadow: 3px 3px 7px 0px rgb(0 0 0 / 10%);
  }
}

.popoverText {
  font-size: 10px;
  white-space: nowrap;
}
