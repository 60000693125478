@import '../constants/colors.scss';

.header {
  padding: 0 16px;
}

.headerContent {
  display: flex;
  justify-content: space-between;

  margin-bottom: 6px;

  h2 {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 1.3rem;
  }

  button {
    margin: 0;
    padding: 0;
  }
}
